import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Heading } from 'rebass'
import { formatDate } from '../../../__utils__/helpers'

const StyledRelatedArticleSummary = styled.div`
  width: 90%;
  height: 100%;

  background-image: url(${props => props.thumbnailImage});

  @media only screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    margin: 2rem 0;
    width: 100%;
  }

  h2 {
    color: ${props => props.theme.colors.black};
    font-size: 1.5rem;
    letter-spacing: -2px;
    margin-top: 2rem;
  }

  .body-text {
    font-size: 1.25rem;
    color: ${props => props.theme.colors.textGray};
    padding: 1rem 0;
  }
  .sub-text {
    font-size: 1rem;
    color: ${props => props.theme.colors.textGray};
  }
`

const ThumbnailImage = styled.div`
  background-image: url(${props => props.thumbnailImage});
  height: 150px;
  position: relative;
  background-size: cover;
  background-position: center center;
  color: ${props => props.theme.colors.white};
`

const RelatedArticleSummary = ({ article, ...props }) => (
  <StyledRelatedArticleSummary {...props}>
    <ThumbnailImage thumbnailImage={article.mastheadImage.file.url} />
    <Heading fontSize={2}>{article.title}</Heading>
    <Text className="body-text">{article.description}</Text>
    <Text className="sub-text">
      {article.author && article.author.displayName
        ? `${article.author.displayName} on ${formatDate(article.publishDate)}`
        : formatDate(article.publishDate)
      }
    </Text>
  </StyledRelatedArticleSummary>
)

RelatedArticleSummary.propTypes = {
  article: PropTypes.shape({
    mastheadImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    author: PropTypes.shape({
      displayName: PropTypes.string.isRequired
    }),
    publishDate: PropTypes.string.isRequired
  }).isRequired
}

export default RelatedArticleSummary
