import React from 'react'
import PropTypes from 'prop-types'
import { EmailShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import {
  Flex, Box, Image, Text
} from 'rebass'
import styled from 'styled-components'
import Button from '../Button'

const StyledSocialLinks = styled(Flex)`
  max-width: 1168px;

  @media only screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    margin: 0;
  }

  *:focus {
    outline: none;
  }
`

const StyledButton = styled(Button)`
  box-sizing: content-box;
  width: 15px;
  height: 15px;

  img {
    max-height: 17px;
    height: 100%;
  }
`

const SocialSharingLinks = ({ url }) => (
  <StyledSocialLinks
    flexWrap="wrap"
    alignItems="center"
    justifyContent={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
  >
    <Box
      width={[1, 'auto', null, null]}
      mr={[2, null, null, 3]}
      mb={[2, 0, null, null]}
    >
      <Text fontSize={0}>
        Share this article:
      </Text>
    </Box>
    <LinkedinShareButton id="linkedin-share" url={url}>
      <StyledButton
        is="span"
        variant="social-sharing"
        mr={[2]}
      >
        <Image alt="LinkedIn logo" className="logo" src="/images/social/white/linkedin-white.svg" />
      </StyledButton>
    </LinkedinShareButton>
    <TwitterShareButton id="twitter-share" url={url}>
      <StyledButton
        is="span"
        variant="social-sharing"
        mr={[2]}
      >
        <Image alt="Twitter logo" className="logo" src="/images/social/white/twitter-white.svg" />
      </StyledButton>
    </TwitterShareButton>
    <EmailShareButton id="email-share" url={url}>
      <StyledButton
        is="span"
        variant="social-sharing"
      >
        <Image alt="mail logo" className="logo" src="/images/social/white/mail-white.svg" />
      </StyledButton>
    </EmailShareButton>
  </StyledSocialLinks>
)

SocialSharingLinks.propTypes = {
  url: PropTypes.string.isRequired
}

export default SocialSharingLinks
