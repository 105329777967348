import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ThemeProvider } from 'styled-components'
import { Flex, Box, Text } from 'rebass'
import { Link, graphql } from 'gatsby'

import BodyText from '../components/elements/BodyText'
import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import Button from '../components/elements/Button'
import RelatedArticleSummary from '../components/elements/RelatedArticle'
import SocialSharingLinks from '../components/elements/SocialSharingLinks'
import useImageBrightness from '../hooks/useImageBrightness'

import { formatDate } from '../__utils__/helpers'
import GatsbyConfig from '../../gatsby-config'

import theme from '../themes'
import { StyledArticlePage, FeaturedArticleHeading, TitleHeading } from './articleStyles'


const ArticlePage = ({ pageContext, data }) => {
  const article = { ...pageContext.articleSummary, ...data.article }
  const { title, author, darkBackgroundOverlay } = article
  const description = article.description || ''
  const content = article.body ? article.body.body : ''
  const isFeatured = !!article.featured
  const thumbnailUrl = article.thumbnailImage
    ? `https://${article.thumbnailImage.file.url.replace(/^\/\//, '')}`
    : ''

  const metaTitle = article.seoMetadata ? article.seoMetadata.metaTitle : `${article.title} |  ${GatsbyConfig.siteMetadata.title}`
  const metaDescription = article.seoMetadata ? article.seoMetadata.metaDescription.metaDescription : article.description
  const metaFeaturedImage = article.seoMetadata && article.seoMetadata.metaFeaturedImage
    ? article.seoMetadata.metaFeaturedImage.file.url
    : thumbnailUrl
  const articleUrl = `https://powershifter.com/blog/${article.slug}/`

  const heroBrightness = useImageBrightness(article.mastheadImage.file.url)

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title={metaTitle}
        description={metaDescription}
        metaImage={metaFeaturedImage}
        canonical={articleUrl}
      />
      <StyledArticlePage>
        <Header variant="transparent" hasLightBackground={heroBrightness > 175} />
        <Flex
          className={darkBackgroundOverlay ? 'masthead bg-overlay' : 'masthead'}
          pt={['120px']}
          pb={['60px']}
          style={{
            backgroundImage: article.mastheadImage ? `url('https:${article.mastheadImage.file.url}')` : ''
          }}
          alignItems="center"
          flexWrap="wrap"
        >
          <Box mx="auto">
            <Box mx="auto" width={[10 / 12]}>
              {isFeatured && (
                <FeaturedArticleHeading className="masthead__featured-article" fontSize={1} postsymbol="">
                  Featured Article
                </FeaturedArticleHeading>
              )}
              <TitleHeading is="h1" fontSize={[4, 4, 5]} postsymbol="">
                {title}
              </TitleHeading>
              <BodyText>{description}</BodyText>
            </Box>
          </Box>
          <Text className="photo-credit" textAlign="right">
            {article.photoCredit && `Photo: ${article.photoCredit.title}`}
          </Text>
        </Flex>
        <Flex className="body" my={6} flexDirection="column">
          <Box mx="auto">
            <Box mx="auto" width={[10 / 12, null, null, null, 8 / 12]}>
              <Box mb={4} />
              <Flex flexWrap="wrap" justifyContent="space-between" className="body-subtitle">
                <Box alignSelf="center" width={[1, 1, 1, 1 / 2]}>
                  {author && author.displayName
                    ? <Text>{`Published by ${author.displayName} on ${formatDate(article.publishDate)}`}</Text>
                    : <Text>{`Published on ${formatDate(article.publishDate)}`}</Text>
                  }
                </Box>
                <Box width={[1, 1, 1, 1 / 2]} justifyContent="end">
                  <SocialSharingLinks url={articleUrl} />
                </Box>
              </Flex>
              <Box>
                <ReactMarkdown source={content} />
              </Box>
              {article.relatedArticles && article.relatedArticles.length > 0 && (
                <Box pt={[4]} className="related-articles">
                  <Text fontSize={0} fontWeight="900" color={theme.colors.blue}>
                    Related articles
                  </Text>
                  <Flex flexWrap="wrap" flexDirection="row" mt={0} mb={32}>
                    {article.relatedArticles.map(relatedArticle => (
                      <Box key={relatedArticle.slug} width={[12 / 12, null, null, null, 6 / 12]}>
                        <Link to={`/blog/${relatedArticle.slug}/`}>
                          <RelatedArticleSummary article={relatedArticle} />
                        </Link>
                      </Box>
                    ))}
                  </Flex>
                </Box>
              )}
              <Box pt={[2]}>
                <Link to="/blog/">
                  <Button>&lt; Back to articles</Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Flex>
        <Footer />
      </StyledArticlePage>
    </ThemeProvider>
  )
}

export default ArticlePage

export const query = graphql`
  query($articleId: String!) {
    article: contentfulBlogEntry(id: { eq: $articleId }) {
      author {
        displayName
      }
      body {
        body
      }
      photoCredit {
        title
      }
      relatedArticles {
        title
        description
        slug
        author {
          displayName
        }
        publishDate
        mastheadImage {
          file {
            url
          }
        }
      }
      darkBackgroundOverlay
      thumbnailImage {
        file {
          fileName
          url
        }
      }
      seoMetadata {
        metaDescription {
          metaDescription
        }
        metaFeaturedImage {
          file {
            url
          }
          description
        }
        metaTitle
      }
    }
  }
`
