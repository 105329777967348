import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import { lighten } from 'polished'
import Heading, { StyledHeading } from '../components/elements/Heading'
import { StyledBodyText } from '../components/elements/BodyText'

export const StyledArticlePage = styled.div`
  .related-articles {
    & > div:first-child {
      margin: 1rem 0;
    }
    @media (max-width: ${props => props.theme.breakpoints[3]}) {
      & > div:first-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }
  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }

    &:active {
      color: ${props => props.theme.colors.linkActive};
    }
  }

  h2,
  h3 {
    letter-spacing: -2px;
  }

  > ${Flex} {
    > ${Box} {
      max-width: 1168px;
      .left-chevron-link {
        text-decoration: none;
        span {
          padding-left: 0.5rem;
        }
        &::before {
          border-style: solid;
          border-width: 0.1em 0.1em 0 0;
          content: ' ';
          display: inline-block;
          height: 0.45em;
          left: 0;
          position: relative;
          top: 0.5em;
          transform: rotate(-135deg);
          vertical-align: top;
          width: 0.45em;
        }
      }
    }
  }

  ${Flex}.masthead {
    background-position: center center;
    background-size: cover;
    box-sizing: content-box;
    color: ${props => props.theme.colors.white};
    height: 412px;
    position: relative;

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      height: auto;
      max-height: 550px;
      padding: calc(2rem + 120px) 0 3rem;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.35);
    }

    &.bg-overlay {
      &:before {
        background-color: rgba(0, 0, 0, 0.5);
      }

      h2,
      p {
        text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      }
    }

    > ${Box} {
      position: relative;
      z-index: 2;
      width: 100%;

      ${StyledHeading} {
        display: block;
        padding: 0.1em 0em;
        color: ${props => props.theme.colors.white};
      }

      ${StyledBodyText} {
        display: block;

        p {
          margin: 0;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        width: 0;
        display: flex;

        li {
          margin-right: 10px;

          a {
            color: white;
            font-size: 14px;
            white-space: nowrap;
            text-decoration: none;
            border-radius: 100vw;
            background-color: ${props => props.theme.colors.blue};
            padding: 5px 15px;

            &:hover {
              background-color: ${props => lighten(0.05, props.theme.colors.blue)};
            }

            &:active {
              background-color: ${props => lighten(0.025, props.theme.colors.blue)};
            }
          }
        }
      }
    }
  }

  ${Flex}.body {
    font-size: 125%;
    line-height: 1.5;
    margin-top: 20px;
    position: relative;

    .body-subtitle {
      font-size: 16px;
      padding-bottom: 2rem;
      color: ${props => props.theme.colors.textGray};
      & > div:nth-child(2) {
        @media (max-width: ${props => props.theme.breakpoints[2]}) {
          text-align: left;
          padding-top: 1.5rem;
        }
        div {
          padding: 0;
        }
        text-align: right;
        .photo-credit-link {
          display: inline;
          a {
            color: ${props => props.theme.colors.textGray};
          }
        }
      }
    }

    img:not(.logo) {
      display: block;
      width: auto;
      max-width: 100%;
      max-height: 700px;
      height: auto;
      text-align: center;
    }
  }

  .photo-credit {
    color: white;
    font-size: 12px;
    opacity: 0.5;
    position: absolute;
    right: 10px;
    bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      font-size: 14px;
    }
`

export const FeaturedArticleHeading = styled(Heading)`
  letter-spacing: -2px;
  margin-bottom: 0.5rem;
  font-weight: 700;
`

export const TitleHeading = styled(Heading)`
  letter-spacing: -2.75px;
  margin-bottom: 1.25rem;

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: 3.75rem;
    letter-spacing: -3.5px;
  }
`
